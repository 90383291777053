<template>
  <Page class="page-categories page-show-category">
    <template #title>Dettagli categoria</template>

    <template #title-extra>
      <router-link class="btn btn-outline-light" :to="{ name: 'categories.edit' }">Modifica</router-link>
      <button type="button" @click.prevent="removeCategory" class="btn btn-outline-danger ml-2">Cancella</button>
    </template>

    <template #default>
      <div class="table-responsive">
        <table class="table table-condensed">
          <tbody>
          <tr>
            <th>Nome</th>
            <td>{{ category.name }}</td>
          </tr>
          <tr>
            <th>Ordinamento</th>
            <td>{{ category.rank }}</td>
          </tr>
          </tbody>
        </table>
      </div>
    </template>
  </Page>
</template>
<script>

import confirmWithModalMixin from '@/libs/Confirm/mixins/confirmWithModal.js';
import Page from '@/views/components/Private/Page.vue';

export default {
  mixins: [confirmWithModalMixin],
  components: {
    Page,
  },
  props: {
    category: {
      type: Object,
      required: true,
    },
  },
  methods: {
    removeCategory () {
      this.confirm('Cancellare la categoria?', 'Sì', 'No')
        .then(confirmed => {
          if (!confirmed) {
            return;
          }

          this.$api.removeCategory(this.category.id)
            .then(() => {
              this.$router.replace({ name: 'categories.list' });
            })
            .catch(this.$log.errors)
          ;
        })
      ;
    },
  },
};

</script>
